import sharedStylesheet from '@earthoptics/shared-components/dist/style.css?url'
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'

import { getUser } from './session.server'
import stylesheet from './tailwind.css?url'

export const links: LinksFunction = () => [
    { rel: 'stylesheet', href: stylesheet },
    { rel: 'stylesheet', href: sharedStylesheet },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
    return json({ user: await getUser(request) })
}

export function ErrorBoundary() {
    const error = useRouteError()

    captureRemixErrorBoundaryError(error)

    return <div>Something went wrong</div>
}

export default function App() {
    return (
        <html className="h-full" lang="en">
            <head>
                <meta charSet="utf-8" />
                <meta content="width=device-width,initial-scale=1" name="viewport" />
                <Meta />
                <Links />
            </head>
            <body className="h-full">
                <Outlet />
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    )
}
